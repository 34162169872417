/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'talent-manageemnt': {
    width: 19,
    height: 17,
    viewBox: '0 0 19 17',
    data: '<g _stroke="#1D1D44" _fill="none" fill-rule="evenodd"><path pid="0" d="M9.629 6.565L7.778 7.581a.1.1 0 01-.147-.104l.356-2.164a.1.1 0 00-.028-.086L6.45 3.69a.1.1 0 01.057-.169l2.079-.315a.1.1 0 00.075-.056l.926-1.96a.1.1 0 01.133-.046c.02.01.038.026.047.047l.927 1.96c.014.03.043.05.075.056l2.08.315a.1.1 0 01.055.169l-1.509 1.536a.098.098 0 00-.027.086l.355 2.164a.1.1 0 01-.147.104l-1.85-1.016a.104.104 0 00-.097 0zm1.579 9.201h.86a1 1 0 00.95-.688l.084-.257a3.282 3.282 0 011.094-1.558l1.079-.843a5.932 5.932 0 002.213-3.794l.434-2.885a.455.455 0 00-.45-.522.688.688 0 00-.665.51l-.562 2.102a4.704 4.704 0 01-1.326 2.214l-.283.266a.2.2 0 01-.321-.224l.643-1.504a.482.482 0 00-.253-.631L14.7 7.95a.587.587 0 00-.714.215c-.533.794-.932 1.336-1.195 1.624-.306.335-1.167 1.136-2.584 2.405h0v2.572a1 1 0 001 1zM7.792 15.766h-.86a1 1 0 01-.95-.688l-.084-.257a3.275 3.275 0 00-1.094-1.558l-1.079-.843a5.94 5.94 0 01-2.213-3.794l-.433-2.885a.454.454 0 01.449-.522c.312 0 .584.209.665.51l.562 2.101a4.704 4.704 0 001.326 2.214l.283.266a.2.2 0 00.321-.224l-.642-1.504a.48.48 0 01.253-.631l.003-.002a.587.587 0 01.714.215c.534.794.932 1.336 1.195 1.624.306.335 1.167 1.136 2.584 2.405h0v2.572a1 1 0 01-1 1z"/></g>'
  }
})
